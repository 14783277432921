import React from "react";
import cn from "classnames";

import styles from "./CustomCheckbox.module.scss";

import { useThemeContext } from "utils/themeContextWrapper";

interface ICustomCheckboxProps {
  className?: string;
  label?: JSX.Element;
  size?: "medium" | "small";
  isChecked: boolean;
  setIsChecked: (value: boolean) => void;
  checkedError: boolean;
  setCheckedError: (value: boolean) => void;
  disabled: boolean;
  modal: boolean;
  isOneRowText?: boolean;
}

const CustomCheckbox: React.FC<ICustomCheckboxProps> = ({
  className,
  label,
  size = "medium",
  isChecked,
  setIsChecked,
  checkedError,
  setCheckedError,
  disabled,
  modal,
  isOneRowText,
}) => {
  const { theme } = useThemeContext();

  const id = modal ? "agree_terms_1" : "agree_terms";

  return (
    <div className={styles.custom_checkbox_wrapper}>
      <input
        id={id}
        type="checkbox"
        className={cn(styles.custom_checkbox, {
          [styles.dark]: theme === "dark",
          [styles.custom_checkbox_wrapper_oneRow]: isOneRowText,
        })}
        name="agree_terms"
        checked={isChecked}
        onChange={(e) => {
          setIsChecked(e.target.checked);

          if (e.target.checked) {
            setCheckedError(false);
          }
        }}
        disabled={disabled}
      />
      <label htmlFor={id} className={cn(styles[size], { [styles.error]: checkedError }, className)}>
        {label}
      </label>
    </div>
  );
};

export default CustomCheckbox;
