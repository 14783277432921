import styles from "./Input.module.scss";

import cn from "classnames";

import { InputProps } from "./Input.props";
import React from "react";

const Input: React.FC<InputProps> = ({
  value,
  error,
  className,
  disabled,
  showError = true,
  ...props
}) => {
  return (
    <div className={styles.inputContainer}>
      <input
        value={value}
        className={cn(className, styles.input, {
          [styles.error]: error,
        })}
        {...props}
        disabled={disabled}
      />
      {showError && <p>{error}</p>}
    </div>
  );
};

export default Input;
