import React, { useCallback, useEffect, useState } from "react";
import styles from "./ModalWindow.module.scss";
import { AnimatePresence, motion } from "framer-motion";
import IconClose from "public/img/ui/icon-close.svg";
import { createPortal } from "react-dom";
import { useScrollLock } from "utils/hooks/useScrollLock";
import cn from "classnames";

interface IModalProps {
  isActive: boolean;
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
  noPadding: boolean;
  header?: string;
  isDetailsModal?: boolean;
}

export const Modal: React.FC<IModalProps> = ({
  isActive,
  setIsActive,
  children,
  noPadding,
  header,
  isDetailsModal,
}) => {
  const escFunction = useCallback((event: KeyboardEvent) => {
    if (event.key === "Escape") {
      setIsActive(false);
    }
  }, []);

  const [isMounted, setIsMounted] = useState<boolean>(false);

  const { lockScroll, unlockScroll }: { lockScroll: () => void; unlockScroll: () => void } =
    useScrollLock();

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (isActive) {
      lockScroll();

      document.addEventListener("keydown", escFunction, false);
    } else {
      unlockScroll();
    }

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [isActive, lockScroll, unlockScroll]);

  const handleModalClose = () => setIsActive(false);
  const handleStopPropagation = (event: React.MouseEvent<HTMLElement>) => event.stopPropagation();

  return (
    isMounted &&
    createPortal(
      <AnimatePresence>
        {isActive && (
          <motion.div
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 50, opacity: 0 }}
            transition={{ duration: 0.3, type: "tween" }}
            className={styles.modal__shadow}
            onMouseDown={handleModalClose}
          >
            <div
              className={styles.modal__wrapper}
              onClick={handleStopPropagation}
              onMouseDown={handleStopPropagation}
            >
              <div className={styles.modal__close__wrapper}>
                {header && <h3 className={styles.modal_actions__title}>{header}</h3>}
                <button
                  className={styles.modal__close}
                  onClick={handleModalClose}
                  aria-label="Close"
                >
                  <IconClose />
                </button>
              </div>
              <div
                className={cn(styles.modal__content, {
                  [styles.border]: isDetailsModal,
                  [styles.noPadding]: noPadding,
                })}
              >
                {children}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>,
      document.body,
    )
  );
};
